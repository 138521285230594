<template>
  <div class="chidren-content">
    <div class="font">
      <p>
        New wholesaler of choice for businesses of all types seeking well
        designed home and gift items
      </p>
      <p>
        that are both functional and fun and are on trend and priced to provide
        excellent margin.
      </p>
      <p>
        We strive to surpass our customers' expectations with quality products,
      </p>
      <p>ease of service and on-time delivery.</p>
    </div>
    <div class="block">
      {{ content }}
    </div>
    <div class="email d-block d-lg-flex d-xl-flex">
      <div class="e-left col-sm-12 col-lg-6 col-xl-6">
        <input
          type="text"
          placeholder="enter your email address..."
          v-model="email"
          @focus="reset"
        />
        <div class="hint" v-if="emailIs.show">{{ emailIs.msg }}</div>
        <div class="button d-flex" @click="emit">
          <img src="~assets/images/icon/email.png" alt="" />
          <p>
            Click here to Sign up for our Newsletter where you will receive
            updates on special promotions, discounts, as well as exciting
            product updates.
          </p>
        </div>
      </div>
      <div class="e-right col-sm-12 col-lg-5 col-xl-5">
        Be sure to continue to check back frequently on this website where we
        will offer promotional specials and products not normally found in our
        catalog.
      </div>
    </div>
  </div>
</template>
<script>
import { getSiteinfoByName } from "network/type";
export default {
  data() {
    return {
      email: "",
      emailIs: {
        show: false,
        msg: "",
      },
      content: "",
    };
  },
  created() {
    getSiteinfoByName("home_tips").then((res) => {
      if (res.data.code == 1) {
        this.content = res.data.data;
      }
    });
  },
  methods: {
    reset() {
      this.emailIs.show = true;
      this.emailIs.msg = "";
    },
    emit() {
      this.$emit("toEmail", this.email, this.emailIs);
    },
  },
};
</script>
<style scoped>
.chidren-content {
  margin-top: 1rem;
}
p {
  margin: 0;
}
.font p {
  margin-top: 0.3rem;
  color: #737373;
  font-size: 0.4rem;
  text-align: center;
}
.block {
  margin-top: 0.75rem;
  text-align: center;
  padding: 0.75rem 0;
  background: #ffd4aa;
}
.block {
  color: #737373;
  font-size: 0.6rem;
  padding: 0.5rem 20%;
}
.block :first-child {
  margin-top: 0.15rem;
}
.email {
  justify-content: space-between;
  padding: 0.75rem 0;
}
.e-left {
}

.e-right {
  margin-top: 2rem;
  color: #999999;
  font-size: 0.4rem;
}
.e-left input {
  padding: 0.3rem 0.2rem;
  border: 1px solid #ededed;
  font-size: 0.35rem;
  color: #999999;
  width: 100%;
  border-radius: 0.2rem;
}
.button {
  margin-top: 0.5rem;
  cursor: pointer;
}
.button img {
  margin: 0 0.5rem;
  width: 1.25rem;
  height: 1rem;
}
.button p {
  font-size: 0.4rem;
  color: #ff7f00;
  text-decoration: underline;
}
.hint {
  font-size: 0.3px;
  color: #cc0000;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  margin: 0.25rem;
}
</style>