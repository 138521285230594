<template>
  <div class="home">
    <banner :banner="banner" />
    <email @toEmail="path" />
  </div>
</template>
<script>
import Banner from "./childcomponents/Swiper";
import Email from "./childcomponents/Email";
import { getHomeBanner } from "network/home";

export default {
  components: { Banner, Email },
  data() {
    return {
      banner: [],
    };
  },
  methods: {
    path(msg, info) {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (msg == "") {
        info.show = true;
        info.msg = "Email: This field is required.";
        return;
      }
      if (!verify.test(msg)) {
        info.show = true;
        info.msg = "Email: Please enter your email address in a valid format.";
        return;
      } else {
        this.$router.push("/contactUs/" + msg);
      }
    },

    getHomeBanner() {
      getHomeBanner().then((res) => {
        this.banner = res.data.data;
      });
    },
  },
  created() {
    // 1.请求多个数据
    this.getHomeBanner();
  },
};
</script>
<style scoped>
.home {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
</style>