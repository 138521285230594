<template>
  <div v-if="banner.length > 0" class="carousel">
    <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide
        class="swiperChild"
        v-for="(item, index) in banner"
        :key="index"
      >
        <img :src="item.imgurl" alt="#" />
      </swiper-slide>
    </swiper>
    <!--    <div class="carouselClick">-->
    <img
      class="carouselClickPre"
      @click="carouselClick('pre')"
      src="~assets/images/icon/carouselPre.png"
      alt="#"
    />
    <img
      class="carouselClickNext"
      @click="carouselClick('next')"
      src="~assets/images/icon/carouselNext.png"
      alt="#"
    />
    <!--    </div>-->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import swiper, { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
swiper.use([Autoplay, Pagination, EffectFade, Navigation]);
export default {
  name: "Carousel",
  props: {
    banner: {
      type: Array,
      default() {
        return null;
      },
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      //配置
      swiperOptions: {
        autoplay: {
          disableOnInteraction: false, //防止手动切换后 自动轮播失效
          delay: 10000,
        },
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        bulletClass: "my-bullet",
      },
    };
  },
  methods: {
    carouselClick(type) {
      let activeIndex = this.$refs.mySwiper.$el.swiper.activeIndex;
      let listLength = this._props.banner.length;
      if (type == "pre") {
        if (activeIndex == 0) {
          this.$refs.mySwiper.$el.swiper.slideTo(listLength, 300, false);
        } else {
          this.$refs.mySwiper.$el.swiper.slideTo(activeIndex - 1, 300, false);
        }
      } else if (type == "next") {
        if (activeIndex == listLength) {
          this.$refs.mySwiper.$el.swiper.slideTo(1, 300, false);
        } else {
          this.$refs.mySwiper.$el.swiper.slideTo(activeIndex + 1, 300, false);
        }
      }
    },
  },
  mounted() {
    // this.$refs.mySwiper.$swiper.autoplay.start();
    this.swiperOptions = {
      autoplay: {
        disableOnInteraction: false, //防止手动切换后 自动轮播失效
        delay: 10000,
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      bulletClass: "my-bullet",
    };
  },
};
</script>

<style scoped>
.swiperChild img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}
</style>
<style>
.carousel {
  position: relative;
}
.carouselClickPre {
  width: 1.2rem;
  height: auto;
  position: absolute;
  z-index: 3;
  top: 46%;
  left: 5%;
  cursor: pointer;
}
.carouselClickNext {
  width: 1.2rem;
  height: auto;
  position: absolute;
  z-index: 3;
  top: 46%;
  right: 5%;
  cursor: pointer;
}
</style>